/*
 * Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,
 * check out our color generator:
 * https://www.ionicframework.com/docs/theming/color-generator
 */

/* Beacon Global colors according to style guide */
:root {
  --ion-color-primary: #faa41a;
  --ion-color-primary-rgb: 250, 164, 26;
  --ion-color-primary-contrast: #FFFFFF;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #dc9017;
  --ion-color-primary-tint: #fbad31;

  --ion-color-secondary: #025f9e;
  --ion-color-secondary-rgb: 2, 95, 158;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #02548b;
  --ion-color-secondary-tint: #1b6fa8;

  --ion-color-tertiary: #8cc6ec;
  --ion-color-tertiary-rgb: 140, 198, 236;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #7baed0;
  --ion-color-tertiary-tint: #98ccee;

  --ion-color-success: #20cc82;
  --ion-color-success-rgb: 32, 204, 130;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #1cb472;
  --ion-color-success-tint: #36d18f;

  --ion-color-warning: #fdc40c;
  --ion-color-warning-rgb: 253, 196, 12;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dfac0b;
  --ion-color-warning-tint: #fdca24;

	--ion-color-danger: #EA3223;
	--ion-color-danger-rgb: 234,50,35;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ce2c1f;
	--ion-color-danger-tint: #ec4739;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

	--ion-color-medium: #6A6C74;
	--ion-color-medium-rgb: 106,108,116;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #5d5f66;
	--ion-color-medium-tint: #797b82;

	--ion-color-dark: #33363F;
	--ion-color-dark-rgb: 51,54,63;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #2d3037;
	--ion-color-dark-tint: #474a52;

	--ion-color-white: #ffffff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #ffffff;

  --ion-color-black: #343741;
  --ion-color-black-rgb: 52, 55, 65;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #2e3039;
  --ion-color-black-tint: #484b54;

	--ion-color-glass: radial-gradient(98.14% 98.14% at 49.85% 1.86%, rgba(46, 155, 228, 0.9) 0%, rgba(1, 61, 101, 0.9) 100%);
	--ion-color-glass-rgb: radial-gradient(98.14% 98.14% at 49.85% 1.86%, rgba(46, 155, 228, 0.9) 0%, rgba(1, 61, 101, 0.9) 100%);
  --ion-color-glass-contrast: #ffffff;
  --ion-color-glass-contrast-rgb: 255, 255, 255;
  --ion-color-glass-shade: rgba(1, 61, 101, 0.9);
  --ion-color-glass-tint: rgba(46, 155, 228, 0.9);
}

/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-headings-font-weight: 700;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

.ios {
}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {
}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
  --ion-color-primary: #faa41a;
  --ion-color-primary-rgb: 250, 164, 26;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #dc9017;
  --ion-color-primary-tint: #fbad31;

  --ion-color-secondary: #025f9e;
  --ion-color-secondary-rgb: 2, 95, 158;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #02548b;
  --ion-color-secondary-tint: #1b6fa8;

  --ion-color-tertiary: #8cc6ec;
  --ion-color-tertiary-rgb: 140, 198, 236;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #7baed0;
  --ion-color-tertiary-tint: #98ccee;

  --ion-color-success: #20cc82;
  --ion-color-success-rgb: 32, 204, 130;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #1cb472;
  --ion-color-success-tint: #36d18f;

  --ion-color-warning: #fdc40c;
  --ion-color-warning-rgb: 253, 196, 12;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dfac0b;
  --ion-color-warning-tint: #fdca24;

  --ion-color-danger: #6a6c74;
  --ion-color-danger-rgb: 106, 108, 116;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #5d5f66;
  --ion-color-danger-tint: #797b82;

  --ion-color-medium: #6a6c74;
  --ion-color-medium-rgb: 106, 108, 116;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5d5f66;
  --ion-color-medium-tint: #797b82;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-black: #343741;
  --ion-color-black-rgb: 52, 55, 65;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #2e3039;
  --ion-color-black-tint: #484b54;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.ios {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #1c1c1c;
  --ion-item-background-activated: #313131;
}

/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.md {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1a1b1e;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}
.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}
.ion-color-dark {
	--ion-color-base: var(--ion-color-dark);
	--ion-color-base-rgb: var(--ion-color-dark-rgb);
	--ion-color-contrast: var(--ion-color-dark-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
	--ion-color-shade: var(--ion-color-dark-shade);
	--ion-color-tint: var(--ion-color-dark-tint);
}
.ion-color-medium {
	--ion-color-base: var(--ion-color-medium);
	--ion-color-base-rgb: var(--ion-color-medium-rgb);
	--ion-color-contrast: var(--ion-color-medium-contrast);
	--ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
	--ion-color-shade: var(--ion-color-medium-shade);
	--ion-color-tint: var(--ion-color-medium-tint);
}
.ion-color-glass {
	--ion-color-base: var(--ion-color-glass);
	--ion-color-base-rgb: var(--ion-color-glass-rgb);
	--ion-color-contrast: var(--ion-color-glass-contrast);
	--ion-color-contrast-rgb: var(--ion-color-glass-contrast-rgb);
	--ion-color-shade: var(--ion-color-glass-shade);
	--ion-color-tint: var(--ion-color-glass-tint);
}
/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
