/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
@import '~swiper/scss';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';
@import '~swiper/css/free-mode';
@import '~swiper/css/thumbs';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proximanova_light/proximanova-light-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/proximanova_light/proximanova-light-webfont.woff')
      format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proximanova_medium/proximanova-medium-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/proximanova_medium/proximanova-medium-webfont.woff')
      format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proximanova_semibold/proximanova-semibold-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/proximanova_semibold/proximanova-semibold-webfont.woff')
      format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proximanova_bold_macroman/proximanova-bold-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/proximanova_bold_macroman/proximanova-bold-webfont.woff')
      format('woff');
  font-weight: 700;
  font-style: normal;
}
:root {
  --ion-font-family: 'Proxima Nova';

  --ion-color-rose: #831843;
  --ion-color-rose-rgb: 131, 24, 67;
  --ion-color-rose-contrast: #ffffff;
  --ion-color-rose-contrast-rgb: 255, 255, 255;
  --ion-color-rose-shade: #73153b;
  --ion-color-rose-tint: #8f2f56;
}

.ion-color-rose {
  --ion-color-base: var(--ion-color-rose);
  --ion-color-base-rgb: var(--ion-color-rose-rgb);
  --ion-color-contrast: var(--ion-color-rose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
  --ion-color-shade: var(--ion-color-rose-shade);
  --ion-color-tint: var(--ion-color-rose-tint);
}

a {
  color: var(--ion-color-secondary);
}
.picker-button {
  color: #343741 !important;
  &:active,
  &:focus {
    outline: none;
  }
}
.alert-button {
  color: var(--ion-color-secondary) !important;
}
ion-modal.date-picker {
  --background: rgba(44, 39, 45, 0.2);
  &::part(content) {
    backdrop-filter: blur(6px);
  }
  ion-content {
    --background: transparent;
    --padding-top: 24vh;
    --padding-start: 20px;
    --padding-end: 20px;
    ion-datetime {
      border-radius: 8px;
    }
  }
}
ion-toolbar {
  --background: #fff;
}
ion-searchbar {
  input {
    --background: rgba(2, 95, 158, 0.1);
  }
}
ion-grid {
  --ion-grid-padding: 0px;
}
ion-toast {
  --background: #000;
  color: #fff;
}
ion-back-button {
  --padding-top: 13px;
  --padding-bottom: 13px;
  --padding-end: 13px;
}
.detail-back-btn {
  ion-back-button {
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-end: 13px;
  }
}

ion-card {
  margin: 0;
  margin: 0px 0px 20px;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(2, 95, 158, 0.25);
  border-radius: 0px 0px 8px 8px;
}

.search-bar {
  border: none;
  ion-searchbar {
    padding: 12px;
  }
}
// ion-button ion-icon {
//   position: absolute;
//   right: 0;
// }
.ion-no-padding-left-right {
  padding-left: 0px;
  padding-right: 0px;
}

.sc-ion-action-sheet-ios-h {
  --button-color: #343741;
}
.action-sheet-cancel {
  .action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: #025f9e;
  }
}

.action-sheet-selected.sc-ion-action-sheet-ios::after {
  background: rgba(82, 96, 255, 0.06);
  border-radius: 5px;
}
.beacon-btn::part(native) {
  box-sizing: border-box;
  border-radius: 10px;
  color: #343741;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  height: 56px;
  letter-spacing: -0.1px;
  text-transform: capitalize;
  white-space: pre-wrap;
}
.beacon-btn-small::part(native) {
  height: 36px;
  font-size: 16px;
  line-height: 16px;
}
.beacon-btn-medium::part(native) {
  height: 44px;
  font-size: 16px;
  line-height: 16px;
}
.beacon-btn-secondary::part(native) {
  font-weight: 700;
  font-size: calc(16px - 0.01vw);
  // font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.1px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0%;
}

.beacon-btn--white::part(native) {
  background-color: #fff;
  border: 1px solid #025f9e;
}

.beacon-btn--orange::part(native) {
  background-color: #faa41a;
  padding: 0%;
}

ion-back-button {
  --color: #000;
  --icon-font-weight: 700;
  display: block;
}

.brand-badge {
  margin-bottom: -1.5rem;
  padding: 12px;
  min-width: 6.6rem;
  max-width: 20.5rem;
  height: -2rem;
  font-size: 28px;
  background: radial-gradient(
    98.14% 98.14% at 49.85% 1.86%,
    rgba(46, 155, 228, 0.9) 0%,
    rgba(1, 61, 101, 0.9) 100%
  );
  border-radius: 8px;
  vertical-align: middle;
  text-overflow: ellipsis;
}

.variation-button {
  border-color: #3880ff !important;
  color: #000;
  height: 3rem;
  max-width: 14rem;
}
.px1 {
	padding: 0 0.25rem;
}
.px2 {
  padding: 0 0.5rem;
}
.px3 {
  padding: 0 1rem;
}
.py2 {
  padding: 0.5rem 0;
}
.py3 {
  padding: 1rem 0;
}
.pr2 {
  padding-right: 0.5rem;
}
.pr3 {
  padding-right: 1rem;
}
.pl2 {
  padding-left: 0.5rem;
}
.pl3 {
  padding-left: 1rem;
}
.pb1 {
  padding-bottom: 0.25rem;
}
.mx2 {
  margin: 0 0.5rem;
}
.mx3 {
  margin: 0 1rem;
}
.my3 {
  margin: 1rem 0;
}
.m3 {
  margin: 1rem 1rem;
}
.mr0 {
  margin-right: 0;
}
.m0 {
  margin: 0 0 0 0;
}
ion-button ion-icon .icon-poisition {
  position: absolute;
  right: 1rem;
}
.thumb-size {
  width: 56px;
}
.med-size {
  width: 80px;
}

.sp-margin {
  margin: 0;
  padding: 5px 12px;
}

.sp-sm-margin {
  margin: 0;
  padding: 5px 3px;
}

.swiper-pagination-bg {
  padding-bottom: 5rem;
}

.swiper-button-prev,
.swiper-button-next {
  top: 89%;
  color: #025f9e;
  z-index: 9999;
}

.swiper-pagination-horizontal {
  top: 86%;
}

.swiper-pagination-bullet {
  // height: 10.75px;
  // width: 10.75px;
  background: rgba(106, 108, 116, 1);
}
.swiper-pagination-bullet-active {
  background: #025f9e;
}
.href-link {
  text-decoration: none;
}
.no-margin {
  margin: 0;
}

.datetime-presentation-date {
  --ion-color-base: var(--ion-color-secondary, #3880ff) !important;
}
.picker-button {
  color: var(--ion-color-secondary) !important;
}
/* Beacon Global typography according to style guide */
h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-transform: capitalize;
}
h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}
h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.4px;
}
h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.363636px;
}
h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.7px;
}
h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.622222px;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.7px;
}
.p-bold {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.7px;
}
/* Inactive this global class as it conflicts with many existing ion-label elements */
// ion-label {
//   font-style: normal;
//   font-weight: 700;
//   font-size: 11px;
//   line-height: 11px;
//   letter-spacing: 1.2px;
//   text-transform: uppercase;
// }
ion-chip {
  ion-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.7px;
  }
}
ion-card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  text-transform: capitalize;
}
ion-card-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: #343741;
}
ion-card-content {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.7px;
}
.ion-card-content-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.7px;
}
.becn-button-large {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  text-transform: capitalize;
}
.becn-button-default {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  text-transform: capitalize;
}
.becn-button-small {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
/* Inactive this global class as it conflicts with the existing ion-badge elements */
// ion-badge {
//   font-style: normal;
//   font-weight: 700;
//   font-size: 13px;
//   line-height: 13px;
//   letter-spacing: 0.382353px;
// }
.action-sheet-title {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
}
.action-sheet-button {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  display: flex;
  align-items: center;
}
.action-sheet-button-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #343741;
  opacity: 0.3;
}
ion-list-header {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.ion-list-title {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;
}
.ion-picker {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 1px;
}
ion-tab-bar {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #343741;
  // padding-top: 2px;
}
.ion-tab-bar-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #343741;
}
.ion-toolbar-button-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.8px;
}
.ion-toolbar-button-underline {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-decoration-line: underline;
}

.ion-toolbar-button-semibold {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.8px;
}
ion-searchbar {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.5px;
}
ion-segment {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.382353px;
}
// ion-title {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 17px;
//   line-height: 17px;
//   text-align: center;
//   letter-spacing: 0.5px;
// }
.ion-title-large {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
}
/* End of Beacon Global typography according to style guide */

.notification-popover {
  --width: 98%;
}
.open-contact {
  color: #025f9e !important;
}
.searchbar-cancel-button {
  color: #000 !important;
}
.h1-equivalent {
  font-size: 28px;
  font-weight: 700;
  color: #025f9e;
}
.list-header {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #025f9e;
}
.action-sheet-button-bold-solid {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #343741;
}

.searchbox-text {
  input {
    padding-inline-start: 34px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    letter-spacing: 0.7px !important;
    text-overflow: ellipsis !important;
  }
}
.beacon-logo {
  height: 3.18rem;
}
